<template>
  <div class="">
   <Header />
   <!-- <Breadcrumb page="TripwePay" :subPage="subPage" /> -->
   <div class="mx-auto max-w-screen-lg px-3 lg:max-w-screen-2xl lg:px-8 xl:max-w-screen-xl xl:px-4 pt-4">
      <breadcrumb></breadcrumb>
      <div class="grid grid-cols-5 gap-7 pb-10 pt-4">
         <SidebarUser />
         <router-view />
      </div>
   </div>
   <Footer />
  </div>
</template>

<script>
import Header from '../../components/Header.vue';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import Footer from '../../components/Footer.vue';
import SidebarUser from '../../components/SidebarUser.vue';

export default {
   name: 'TripwePay',
   props: {
      subPage: {
         type: String,
         default: 'Transactions',
         required: false,
      },
   },
   components: {
      Header,
      Breadcrumb,
      SidebarUser,
      Footer,
   },
};
</script>
